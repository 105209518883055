import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';
import useLayoutQuery from '@graphql/queries/LayoutQuery';

import Page from '@components/layout/Page';
import Hero from '@components/common/Hero';
import MetaPage from '@components/common/MetaPage';
import Header from '@components/layout/Header';
import Footer from '@components/layout/Footer';
import LayoutRoot from '@components/layout/LayoutRoot';

export default () => {
  const langcode = Cookies.get('ipos-language') ? Cookies.get('ipos-language') : 'pl';
  const layoutData = useLayoutQuery();

  return (
    <Page noMarginBottom={true}>
      <StaticQuery
        query={graphql`
          query {
            errorHeroImage: file(relativePath: { eq: "404@2x.jpg" }) {
              childImageSharp {
                fixed(width: 1600, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            allSiteSettingEntityBrakStrony404 {
              edges {
                node {
                  field_meta_title
                  field_settings_button_text
                  field_settings_tresc {
                    value
                  }
                  langcode
                }
              }
            }
            allSiteSettingEntityUrls {
              edges {
                node {
                  langcode
                  field_urls_array
                }
              }
            }
          }
        `}
        render={data => {
          const translation = data.allSiteSettingEntityBrakStrony404.edges.find(
            item => item.node.langcode === langcode
          ).node;
          const allUrls = data.allSiteSettingEntityUrls.edges.find(
            item => item.node.langcode === langcode
          ).node.field_urls_array;
          const otherLangUrl = {};

          data.allSiteSettingEntityUrls.edges.map(edge => {
            otherLangUrl[edge.node.langcode] = '/';
          });

          return (
            <LayoutRoot>
              <Header
                content={layoutData.menu[langcode]}
                langcode={langcode}
                urls={allUrls}
                otherLangUrl={otherLangUrl}
              />
              <MetaPage title={translation.field_meta_title} />
              <Hero
                size="medium"
                image={data.errorHeroImage.childImageSharp}
                paragraph={translation.field_settings_tresc.value}
                buttonText={translation.field_settings_button_text}
                buttonRoute="/"
                text="dark"
                noArrow={true}
              ></Hero>
              <Footer content={layoutData.footer[langcode]} langcode={langcode} urls={allUrls} />
            </LayoutRoot>
          );
        }}
      />
    </Page>
  );
};
